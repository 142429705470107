export function fetchEditBannerPageData(id:number) {

    const {get} = useOFetchCustom(`banners/${id}/edit`)
    const editBannerData = useEditBannerData()

    async function fetch() {
        return new Promise((resolve, reject) => {
            get(undefined, {
                onSuccess: (response: any) => {
                    editBannerData.value = response
                    resolve(response)
                },
                onError: (error: any) => {
                    reject(error)
                }
            })
        })
    }

    return {fetch, editBannerData}
}

export default defineNuxtRouteMiddleware(async (to, from) => {

    const {fetch} = fetchEditBannerPageData(Number(to.params.id))

    try{
        await fetch()
    }
    catch (error: any) {
        return navigateTo('/store')
    }

})
